@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
